<template>
  <v-container class="pa-6" fluid>
    <v-row>
      <v-col cols="3">
        <h2 class="sidebar-title mb-6">Restaurants</h2>
        <Button v-if="getUserData.roleNames[0].toLowerCase() === 'admin'" class="mb-3" styleType="secondary"
          text="New restaurant group" @handleClick="openAreaModal" />
        <Button styleType="secondary" class="mb-3" text="New restaurant subgroup" @handleClick="openModal" />
        <v-text-field v-if="getUserData.roleNames[0].toLowerCase() === 'admin'" @input="handleAreaSearch" solo
          label="Find restaurant group" class="standart-input mt-3" flat prepend-inner-icon="mdi-magnify"
          type="text"></v-text-field>
        <v-text-field @input="handleVenueGroupSearch" solo label="Find restaurant subgroup" class="standart-input" flat
          prepend-inner-icon="mdi-magnify" type="text"></v-text-field>
        <GroupTab @getAreasData="getAreasData" @getVenueGroups="getVenueGroups" @folderClick="folderClick"
          @tabClick="tabClick" :isArea="isArea" :list="isArea ? getAreasList : getVenueGroupsData"
          :activeFolderId="activeAreaId" :activeTabId="activeVenueGroupId" :showDots="true" class="" />
      </v-col>
      <v-col>
        <div class="standart-card mt-15">
          <v-row>
            <v-col class="col-lg-10 col-9">
              <v-text-field @input="handleVenueSearch" solo label="Find restaurant" class="standart-input" flat
                prepend-inner-icon="mdi-magnify" type="text"></v-text-field>
            </v-col>
            <v-col>
              <router-link :to="isLoading ? '#' : '/venue/new'" class="standart-button standart-button-secondary">New
                restaurant</router-link>
            </v-col>
          </v-row>
          <EntityList :isLoading="isVenueLoading" :list="entityList" :isSearched="isSearched" :detailUrl="'venue'"
            @handleDelete="deleteEntityItem" :groupId="getActiveVenueGroupId" />
        </div>
      </v-col>
    </v-row>
    <Modal v-model="isModalOpen" @saveForm="submitVenueGroup" :areas="getAreasList" title="New restaurant subgroup" />
    <AreaModal v-model="isAreaModalOpen" @saveAreaForm="submitArea" title="New restaurant group" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { debounce } from 'vue-debounce';
import GroupTab from '../components/GroupTab.vue';
import EntityList from '../components/EntityList.vue';
import { TOAST_TYPES } from '@/data';
import Button from '@/components/commonComponents/Button';
import Modal from '@/components/modals/Modal';
import AreaModal from '@/components/modals/AreaModal';

export default {
  name: 'Venues',
  components: {
    GroupTab,
    EntityList,
    Button,
    Modal,
    AreaModal
  },
  data() {
    return {
      venueSearchValue: '',
      areaSearchValue: '',
      venueGroupSearchValue: '',
      areasDataModal: [],
      isModalOpen: false,
      isAreaModalOpen: false,
      isArea: false,
    };
  },
  computed: {
    ...mapGetters({
      venueGroups: 'venueGroups/getData',
      venueGroup: 'venueGroup/getItem',
      entityList: 'venues/getData',
      isVenueLoading: 'venues/isLoading',
      getActiveAreaId: 'areas/getActiveAreaId',
      getAreasList: 'areas/getList',
      getVenueGroupsData: 'venueGroups/getData',
      getActiveVenueGroupId: 'venueGroups/getActiveVenueGroupId',
      getSearch: 'venues/getSearch',
      getAreaSearch: 'areas/getSearch',
      getVenueGroupSearch: 'venueGroups/getSearch',
      isLoading: 'venues/isLoading',
      getUserData: 'app/getUserData',
    }),
    isSearched() {
      return Boolean(this.venueSearchValue.length);
    },
    activeVenueGroupId() {
      return this.getActiveVenueGroupId;
    },
    activeAreaId() {
      return this.getActiveAreaId;
    },
  },
  created() {
    if (this.getUserData.roleNames[0].toLowerCase() === 'dealer') {
      this.getVenueGroups(this.getUserData.areaId)
    } else {
      this.getAreasData()
    }
  },
  mounted() {
    if (this.getUserData.roleNames[0].toLowerCase() === 'dealer') {
      this.setActiveVenueGroupId(null)
      if (!this.getUserData.areaId) {
        this.$router.go();
      }
      this.getVenueGroups(this.getUserData.areaId)
    } else {
      this.setVenuesList([]);
      this.setActiveVenueGroupId(null)
      if (this.getSearch) {
        this.fetchVenuesById(this.getActiveVenueGroupId);
        this.clearSearch();
      }
      if (this.getAreaSearch) {
        this.isArea = true
      }
      if (this.getVenueGroupSearch) {
        this.isArea = false
      }

    }
  },
  watch: {
    venueGroupSearchValue(newValue, oldValue) {
      if (newValue) this.isArea = false
      else this.isArea = true
    }
  },
  methods: {
    ...mapActions({
      fetchVenueGroups: 'venueGroups/fetchData',
      fetchAreas: 'areas/fetchData',
      fetchArea: 'areas/fetchItem',
      addVenueGroup: 'venueGroups/addItemToDB',
      addArea: 'areas/addItemToDB',
      editVenueGroup: 'venueGroups/changeItemToDB',
      fetchVenue: 'venues/fetchData',
      searchEntity: 'venues/searchVenues',
      searchVenueGroups: 'venueGroups/searchVenueGroup',
      searchArea: 'areas/search',
      deleteEntity: 'venues/deleteItem',
    }),
    ...mapMutations({
      setActiveVenueGroupId: 'venueGroups/setActiveVenueGroupId',
      setActiveAreaId: 'areas/setActiveAreaId',
      addToVenueListGroup: 'venueGroups/addToList',
      addToAreaList: 'areas/addToList',
      setVenueGroupSearch: 'venues/setSearch',
      setAreaSearch: 'areas/setSearch',
      setSearch: 'venues/setSearch',
      clearSearch: 'venues/clearSearch',
      clearAreaSearch: 'areas/clearSearch',
      clearVenueGroupSearch: 'venueGroups/clearSearch',
      setVenuesList: 'venues/setList',
      setAreasList: 'areas/setList',
      setVenueGroupsData: 'venueGroups/setData',
    }),
    getAreasData() {
      this.isArea = true;
      this.fetchAreas().then((data) => {
        const tempData = data.map(item => {
          const venueGroups = []
          this.fetchVenueGroups(`VenueGroups?areaId=${item.id}`).then((data) => {
            venueGroups.push(...data)
          })
          return {
            ...item,
            child: venueGroups
          }
        })
        this.setAreasList(tempData)
      })
    },
    getVenueGroups(id) {
      this.setActiveAreaId(id)
      this.fetchVenueGroups(`VenueGroups?areaId=${id}`).then(data => {
        this.setVenueGroupsData(data);
      });
    },
    folderClick(id) {
      this.setActiveAreaId(id)
    },
    tabClick(id) {
      this.setActiveVenueGroupId(id);
      this.fetchVenuesById();
    },
    fetchVenuesById() {
      this.fetchVenue(`/venues?areaId=${this.getActiveAreaId}&groupId=${this.getActiveVenueGroupId}`);
    },
    deleteEntityItem(id) {
      this.deleteEntity(id).then(data => {
        if (data) {
          this.$toast.open({ message: 'Venue has been successfully deleted!', type: TOAST_TYPES.SUCCESS });
        }
      });
    },
    handleVenueSearch: debounce(function (searchQuery) {
      this.venueSearchValue = searchQuery;
      this.setSearch(searchQuery);
      if (!this.getActiveAreaId && !this.getActiveVenueGroupId) {
        this.searchEntity(`/venues?partOfName=${searchQuery}`)
      } else if (this.getActiveAreaId && !this.getActiveVenueGroupId) {
        this.searchEntity(`/venues?areaId=${this.getActiveAreaId}&partOfName=${searchQuery}`)
      } else if (!this.getActiveAreaId && this.getActiveVenueGroupId) {
        this.searchEntity(`/venues?groupId=${this.getActiveVenueGroupId}&partOfName=${searchQuery}`)
      } else {
        this.searchEntity(`/venues?areaId=${this.getActiveAreaId}&groupId=${this.getActiveVenueGroupId}&partOfName=${searchQuery}`)
      }
    }, '1000ms'),
    handleAreaSearch: debounce(function (searchQuery) {
      this.areaSearchValue = searchQuery;
      this.setAreaSearch(searchQuery);
      const url = `/areas?partOfName=${searchQuery}`;
      this.searchArea(url).then((data) => {
        this.areasDataModal = this.getAreasList
        this.setAreasList(
          data.map(item => {
            const venueGroups = []
            this.fetchVenueGroups(`VenueGroups?areaId=${item.id}`).then((data) => {
              venueGroups.push(...data)
            })
            return {
              ...item,
              child: venueGroups
            }
          })
        )
      })
    }, '1000ms'),
    handleVenueGroupSearch: debounce(function (searchQuery) {
      if (searchQuery) {
        this.isArea = false
        this.venueGroupSearchValue = searchQuery;
        this.setVenueGroupSearch(searchQuery);
        const url = this.getActiveAreaId
          ? `/venueGroups?areaId=${this.getActiveAreaId}&partOfName=${searchQuery}`
          : `/venueGroups?partOfName=${searchQuery}`;
        this.searchVenueGroups(url)
      } else {
        if (this.getUserData.roleNames[0].toLowerCase() === "admin") {
          this.getAreasData()
        } else {
          this.getVenueGroups(this.getUserData.areaId)
        }
      }
    }, '1000ms'),
    openModal() {
      this.isModalOpen = true;
    },
    openAreaModal() {
      this.isAreaModalOpen = true;
    },
    submitVenueGroup(form) {
      let formData = { areaId: form.areaId, name: form.name };
      this.addVenueGroup(formData).then(data => {
        if (data) {
          formData.id = data;
          this.addToVenueListGroup(formData);
          this.isModalOpen = false;
          this.$.toast({ message: 'Restaurant subgroup has been successfully added!', type: TOAST_TYPES.SUCCESS });
        }
      }).finally(() => {
        if (this.getUserData.roleNames[0].toLowerCase() === "admin") {
          this.getAreasData()
        } else {
          this.getVenueGroups(this.getUserData.areaId)
        }
      })
    },
    submitArea(form) {
      let formData = { name: form.name };
      this.addArea(formData).then(data => {
        if (data) {
          formData.id = data;
          this.addToAreaList(formData);
          this.isAreaModalOpen = false;
          this.$toast.open({ message: 'Restaurant group has been successfully added!', type: TOAST_TYPES.SUCCESS });
        }
      }).finally(() => this.getAreasData());
    },
  },
};
</script>

<style lang="scss"></style>
